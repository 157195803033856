<template>
  <a v-if="genre" :href="'/kategori/' + genre.slug " class="badge bg-info text-white mr-1">{{ genre.name }}</a>
</template>

<script>

import axios from "axios";

export default {
  name: "Genre",
  props:["item"],
  data (){
    return{
      genre: null
    }
  },
  mounted() {
    var i = this.item;

    axios
        .get("https://cd.tufangokmenler.com/api.php?key=genre&id=" + i.id)
        .then(response => (this.genre = response.data))
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push('/404')
          }
        });
  }
}
</script>

<style scoped>

</style>
