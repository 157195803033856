<template>
  <div class="col-3 mb-5">
    <figure class="h-100 d-flex flex-column align-items-center justify-content-between border p-3">
      <a :href="this.baseImageUrl + 'original' + item.logo_path" v-if="item.logo_path != null"  data-fancybox="movie-logos" class="m-auto"><img v-bind:src="this.baseImageUrl + 'w500' + item.logo_path" alt="" class="img-fluid"></a>
      <img v-else v-bind:src="'https://ui-avatars.com/api/?color=000000&background=ffffff&bold=true&name=' + item.name" class="img-fluid">
      <figcaption class="mt-auto">
        {{ item.name }}
      </figcaption>
    </figure>
  </div>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";

export default {
  name: "MovieLogos",
  props:["item"]
}
</script>

<style scoped>

</style>
